<template>
<b-container>
    <b-alert v-model="hasErrors" variant="danger">
      {{ $t('Upload.ImageUpload.error_message', {upload_error}) }}
    </b-alert>
    <b-row align-h="center" align-v="center" class="d-flex align-content-center flex-wrap">
      <b-col> 
        <ImageUploadForm :file="file" @updateFile="updateFile" v-if="!file" />
      </b-col>
    </b-row>
    <b-row align-h="center" align-v="center" class="d-flex align-content-center flex-wrap">
      <b-col> 
        <ImageEditor :uploading="uploading" @updateAnnotation="updateAnnotation" :file="file" v-if="file" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>

        <b-button @click="uploadFile" block class="button" variant="primary" :disabled="!file" role="submit">{{ $t('Upload.ImageUpload.upload_image') }}</b-button>

      </b-col>
      <b-col>
        <b-button @click="deleteImage" block class="button" variant="danger" :disabled="!file">{{ $t('Upload.ImageUpload.delete_image') }}</b-button>
      </b-col>
    </b-row>

</b-container>
</template>

<script>
import { apiService } from '../services'
import ImageEditor from './upload/ImageEditor.vue'
import ImageUploadForm from './upload/ImageUploadForm.vue'

export default {
  name: 'ImageUpload',
  props: {
      'user': Object,
      'settings': Function,
  },
  methods: {
      uploadFile() {
          this.uploading = true
          let formData = new FormData()
          formData.append('image', this.file)
          formData.append('thumbnail', "")
          formData.append('owner', this.user.url)
          formData.append('censor', JSON.stringify(this.annotations))
          apiService.get({url: 'images/', method: 'POST', body: formData, json: false})
          .then(response => {
            this.uploading = false
            this.$router.push({name: 'ImageMeta', params: {id: response['id']}})
          }) 
          .catch(error => {
            this.uploading = false
            this.upload_error = error
            this.hasErrors = true
          })
      },
      deleteImage(){
        this.file = null
      },
      updateAnnotation(annotations){
        this.annotations = annotations
      },
      updateFile(file){
        this.file = file
      }
  },
  data() {
      return {
          file: null,
          uploading: false,
          upload_error: "",
          hasErrors: false,
          annotations: [],
      }
  },
  computed: {
  },
  watch: {
  },
  components: {
    ImageEditor,
    ImageUploadForm
  }

 }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.button{
  margin-top: 5px;
}
</style>
