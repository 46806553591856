<template>

    <b-container fluid>
        <ImageUpload v-on="$listeners" v-if="activeComponent == 'upload'" :user="user" :settings="settings" />
        <ImageAnnotation v-on="$listeners" v-if="activeComponent == 'annotation'" :user="user" :settings="settings"  />
        <ImageMeta v-on="$listeners" v-if="activeComponent == 'meta'" :user="user" :settings="settings" />        

    </b-container>

</template>

<script>
import ImageUpload from '../components/ImageUpload'
import ImageAnnotation from '../components/ImageAnnotation'
import ImageMeta from '../components/ImageMeta'


export default {
  name: 'ImageView',
  props: {
      'user' : Object,
      'settings': Function
  },
  components: {
      ImageUpload,
      ImageAnnotation,
      ImageMeta,
  },
  methods: {
  },
  data() {
      return {
          
      }
  },
  computed: {
      activeComponent() {
          switch(this.$route.name) {
            case 'ImageUpload':
                return 'upload';
            case 'ImageAnnotation':
                return 'annotation';
            case 'ImageMeta':
                return 'meta';
            default:
                return 'list';
          }
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
