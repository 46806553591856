<template>

<b-container>
    <b-overlay :show="no_permission" rounded="lg">
    <b-skeleton-wrapper :loading="data_loading">

              <template #loading>
              </template>
          <b-img @click="goto('annotation')" right :src="img" thumbnail :alt="$t('ImageMeta.thumbnail_title')"></b-img>
    </b-skeleton-wrapper>

    <h1>{{ $t('ImageMeta.title') }}</h1>
        <b-skeleton-wrapper :loading="data_loading">

              <template #loading>
            <b-skeleton animation="wave" width="55%"></b-skeleton>
            <b-skeleton animation="wave" width="25%"></b-skeleton>
              </template>

      <p><small class="text-muted">ID: {{image.id}}</small><br /></p>
    </b-skeleton-wrapper>

      <p class="lead">{{$t('ImageMeta.lead')}}</p>

    <hr/>

    <h2>{{ $t('activities') }}</h2>
    <p>{{$t('ImageMeta.activities_open_help')}}</p>
    <b-skeleton-wrapper :loading="data_loading">
      <template #loading>
        <b-skeleton animation="wave" width="85%"></b-skeleton>
        <b-skeleton animation="wave" width="65%"></b-skeleton>
        <b-skeleton animation="wave" width="25%"></b-skeleton>
      </template>
      <editor
        api-key="7pd6dml97de9770z0tsjh0vy8qubtp1tdwabo8pvzmbsy7hy"
        v-model="open_activity_description"
        :init="{
          height: 150,
          menubar: false,
          plugins: [
            'advlist autolink lists link charmap preview',
            'searchreplace',
            'insertdatetime paste code help wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor | \
            bullist numlist | removeformat | help'
        }"
      />
    </b-skeleton-wrapper>
    <br />
    <h3>{{$t('ImageMeta.tags_title')}}</h3>
      <p>{{$t('ImageMeta.activities_help')}} <kbd>{{$t('ImageMeta.enter')}} <b-icon icon="arrow-return-left" aria-hidden="true"></b-icon></kbd> {{$t('ImageMeta.key')}}.</p>
      <div class="activities">
          <b-skeleton-wrapper :loading="data_loading">
              <template #loading>
            <b-skeleton animation="wave" width="85%"></b-skeleton>
            <b-skeleton animation="wave" width="55%"></b-skeleton>
              </template>
        <b-badge v-for="item in selected_activities" :key="item.id" class="activity" variant="dark">
          {{ getTranslated(item.translations, 'name') }}  <b-button @click="deleteActivity(item)" variant="dark" size="sm"><b-icon icon="trash" font-scale="1" aria-hidden="true"></b-icon></b-button>
        </b-badge>
        
          </b-skeleton-wrapper>
      </div>
      <vue-typeahead-bootstrap
        v-model="activity_query"
        :ieCloseFix="false"
        :data="activities"
        :serializer="item => getTranslated(item.translations, 'name')"
        @hit="addActivity"
        :disabledValues="selected_activities"
        :placeholder="$t('ImageMeta.activities_placeholder')"
        @input="lookupActivities"
        @keyup.enter="enterActivity"
      />
      <div v-if="show_hint">{{ $t('ImageMeta.press')}}  <kbd>{{$t('ImageMeta.enter')}} <b-icon icon='arrow-return-left' aria-hidden='true'></b-icon></kbd> {{$t('ImageMeta.add_activities_hint', {'name': activity_query})}}</div>

      <hr />
      <h2>{{$t('location')}}</h2>
        <p>{{ $t('ImageMeta.location_description') }}</p>

      <p><b-form-select v-model="selected_location" :options="locations"></b-form-select></p>

      <hr />

      <b-row>

                <b-col ><b-button variant="secondary" @click="goto('upload')" block size="lg"><b-icon icon="upload" ></b-icon> {{ $t('ImageMeta.new_image') }}</b-button></b-col>
                 <b-col ><b-button variant="warning" :disabled="image.public" @click="publish()" block size="lg"><b-icon icon="unlock" ></b-icon> {{ $t('ImageMeta.publish_image') }}</b-button></b-col>

                <b-col  v-if="user.is_superuser" ><b-button variant="primary" @click="goto('annotation')" :class="{disabled: !image.public}" block size="lg"><b-icon icon="bounding-box" ></b-icon> {{ $t('ImageMeta.annotate_image') }}</b-button></b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">

        <hr />
      <b-button v-b-toggle.help block variant="light"><b-icon icon="question-circle"></b-icon> {{ $t('help') }}</b-button>
      </b-col>
      </b-row>
     <template #overlay>
        <div class="text-center">
          <b-icon icon="key-fill" font-scale="3" animation="throb"></b-icon>
          <p>{{ $t('ImageMeta.no_permission') }}<b-link href="/dash">{{$t('dashboard')}}</b-link></p>
        </div>
     </template>

    </b-overlay>


</b-container>

</template>

<script>
import { apiService } from '../services'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import _ from 'lodash';
import { translationService } from '../services'

import '@recogito/annotorious/dist/annotorious.min.css';
 import Editor from '@tinymce/tinymce-vue'

export default {
  name: 'ImageMeta',
  components: {
    VueTypeaheadBootstrap,
    'editor': Editor,
  },
  props: [
    'user',
  ], 
  methods: {
    getTranslated: translationService.getTranslated,
    // Publish Image
    publish(){
      this.image.public = !this.image.public;
      this.updateRemoteImage();
    },
    // Uploads the image metadata with PATCH request
    updateRemoteImage(){
      let image = this.image;
      
      delete image['image'] // Otherwise we would need to upload an image.
      delete image['thumbnail'] // Otherwise we would need to upload an image.


      apiService.get({url: image['url'], method: 'PATCH', body: image, locale: this.$i18n.locale})
        .then(response => {
          this.image = response
          this.$emit('toast', this.$t('ImageMeta.success_toast.title'), this.$t('ImageMeta.success_toast.message'), "success")
        })
        .catch(error => {
          this.$emit('toast', this.$t('ImageMeta.error_toast.title'), this.$t('ImageMeta.error_toast.message', {error}), "danger")
        })
    
    },
    // Queries API for Activities
    lookupActivities: _.debounce(function (){
      apiService.get({url:`activities/?name=${this.activity_query}`, method: 'GET', locale: this.$i18n.locale, json: false})
                        .then(response => {

                          let activities = response.results
                          
                          // Checking whether the activity has already been chosen
                          if(this.selected_activities.length > 0){
                            let selected = []
                            this.selected_activities.forEach((element) => {
                              selected.push(element['id'])
                            })
                            activities = response.results.filter((element) => {
                              return !selected.includes(element['id'])
                            })
                          } 

                          // Replacing the list
                          this.activities = activities

                          // In case no activites match the query the hint to add a new one is displayed
                          if(activities.length == 0) {
                            this.show_hint = true
                          } else {
                            this.show_hint = false
                          }

                          }) 
                        }, 250),
    // Pushes activity to the selected ones. Due to watch function the image object is PATCHed automatically                    
    addActivity(activity){
      this.selected_activities.push(activity)
      this.activity_query = ""
    },
    // Fired upon enter, if no activity is available a new one with search string ist created and then added to the selected array.
    enterActivity(){
      if(this.activities.length==0){

        const activity = {
          translations: {
            [this.$i18n.locale || 'en']: {
              name: this.activity_query
            }
          }
        }

        apiService.get({url:'activities/', method:'POST', locale: this.$i18n.locale, body:activity})
          .then(response => {
            this.addActivity(response)
          })
      }
    }, 
    // Deletes the connection to an activity
    deleteActivity(activity){
      this.selected_activities = this.selected_activities.filter(item => item !== activity)
    },
    deleteAnnotation(annotation) {
      const id = annotation['id'].replace('#', '');
      apiService.get({url: `annotations/${id}/`, method: 'DELETE'})
              .then(response => {
                console.log(response);  // TODO: Catch Error, User Feedback.
              });
    },
    // Synchronizes remote open activity
    // TODO: Optimization, debounce! (Actually just overwrites the translations, should rather overwrite the right language)
    updateOpenActivity: _.debounce(function (){
        let open_activity = this.open_activity
        open_activity['translations'] = {
              [this.$i18n.locale || 'en']: {
                description: this.open_activity_description
              }
            }

          
          apiService.get({url:open_activity.url, method:'PATCH', locale: this.$i18n.locale, body:open_activity})
            .then(response => {
              if(response.count == 1)
                //this.open_activity = response.results[0]
                //this.open_activity_description = this.getTranslated(this.open_activity.translations, 'description')
                this.$emit('toast', this.$t('ImageMeta.success_toast.title'), this.$t('ImageMeta.success_toast.message'), "success")
            })
            .catch(error => {
              console.log(error)
              this.$emit('toast', this.$t('ImageMeta.error_toast.title'), this.$t('ImageMeta.error_toast.message', {error}), "danger")
            })
      }, 250),
    goto(target) {
      if(target === 'upload'){
        this.$router.push({name: 'ImageUpload'})
      }

      if(target === 'annotation'){
        if(this.image.public == false){
            this.$bvModal.msgBoxOk('Please publish the Image first!', {
              title: 'Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'primary',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
          } else {
            this.$router.push({name: 'ImageAnnotation', params: {id: this.image.id}})
          }
      }
    },

    init(){

    // Image ID from Route
    const image_id = this.$route.params.id;

    // Initializing variables.

    this.profile = this.user.profile
    
    // Meta-Data for Image
    apiService.get({url: 'locations/', locale: this.$i18n.locale, method: 'GET'})
            .then(response => {
                if(response.count > 0){
                    response.results.forEach(location => {
                        this.locations.push({
                            value: location.url,
                            text: this.getTranslated(location.translations, 'name')
                        })
                    })
                }
            })
            .then(() => {
              apiService.get({url: `images/${image_id}/`})
                    .then(response=>{
                      if(response){
                        this.image = response

                        // First check if user has permission to edit record
                        if(this.image.owner != this.user.url && !this.user.is_superuser){
                          this.no_permission = true
                          return 
                        }

                        // Retrieves all activity tags for the images
                       this.image.activities.forEach(activity => {
                          apiService.get({url: activity, method: 'GET', locale: this.$i18n.locale, json: false})
                            .then(response => {
                              this.selected_activities.push(response)
                            })
                        })

                        // Retrieves the Open Activity description in the right language for the user
                        apiService.get({url: `activitiesopen/?image=${image_id}`, method: 'GET', locale: this.$i18n.locale})
                            .then(response => {
                              if(response.count == 1){
                                this.open_activity = response.results[0]
                                this.open_activity_description = this.getTranslated(this.open_activity.translations, 'description')
                              } else {
                                // TODO: Catch Error
                              }
                            })

                        // Add Location to image
                        if(this.image.location)
                          this.selected_location = this.image.location

                        this.data_loading = false

                      }
                    })
                    .catch(error => {
                        this.$emit('toast', this.$t('ImageMeta.error_toast.title'), this.$t('ImageMeta.error_toast.message', {error}), "danger")
                    })
                   
            })
    

    }

  },
  
  data() {
      return {
        activity_query: "",
        activities: [],     // List of Activities. Initialized on Load.
        selected_activities: [],                  // List of selected Activities
        search: '',         // Search String for activities 
        image: {},          // Image object
        show_hint: false,   // Show Hint to enter for adding activities
        data_loading: true,
        locations: [],
        selected_location: null,
        no_permission: false,
        profile: null,
        open_activity_description: "",
        open_activity: {},
      }
  },
  mounted() { 
    this.init()         
  },
  computed: {
      img(){
         return `${process.env.VUE_APP_DJANGO_BASE_URL}image/thumbnail/${this.image.id}`; 
      },
      full_img(){
         return `${process.env.VUE_APP_DJANGO_BASE_URL}image/${this.image.id}`; 
      },
  },
  watch: {
    open_activity_description(){
      this.updateOpenActivity()
    },
    selected_activities() {
        // TODO: Check for changes, otherwise the image is PATCHed each time an activity is added on pageload
      let urls = []

      this.selected_activities.forEach(activity=> {
        urls.push(activity.url)
      })

      this.image.activities = urls

      if(!this.data_loading)
        this.updateRemoteImage()
    
    },
    selected_location(newVersion, oldVersion){
        if(newVersion != oldVersion){
            this.image.location = this.selected_location
            
            if(!this.data_loading)
              this.updateRemoteImage()
        }
    },
    '$route.params.id': function(){
      this.init()
    }
  },

 }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.activities{
  min-height:7vh;
  margin: 0;

}

.activity{
  margin-right: 5px;
  margin-bottom: 5px;
}

</style>
