<template>
<div>
    <h2>{{ $t('Upload.ImageEditor.title') }}</h2>
    <p class="lead">{{ $t('Upload.ImageEditor.lead') }}</p>
        <b-row>
      <b-col>
              <b-button v-b-toggle.help block variant="light"><b-icon icon="question-circle"></b-icon> {{ $t('help') }}</b-button>
              <hr />
      </b-col>
    </b-row>
    <b-row>
        <b-col>
            <b-overlay :show="uploading" rounded="sm">
            <img class="image" v-if="file" :src="img" id="annotation_image" />
            </b-overlay>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <b-button @click="resetImage" block class="button" variant="warning">{{ $t('Upload.ImageEditor.reset_image') }}</b-button>
            <hr />
        </b-col>
    </b-row>
</div>


</template>

<script>
import { Annotorious } from '@recogito/annotorious';
import '@recogito/annotorious/dist/annotorious.min.css';

export default {
  name: 'ImageEditor',
  components: {
  },
  props: [
    'user',
    'file',
    'uploading'
  ], 
  methods: {
    resetImage(){
        this.anno.destroy()
        this.init()
    },
    deleteAnnotation(annotation) {
        console.log(annotation)
    },
    craeteOrUpdateAnnotation(annotation) {
        console.log(annotation)
        console.log(this.anno.getAnnotations())
        this.$emit('updateAnnotation', this.anno.getAnnotations())
    },
    saveAnnotation(selection){
        this.anno.updateSelected(selection, true);
    },

    // Triggered once the image loaded. Inits the Annotrious object, adds event listeners for Creating, Updating, Deleting Annotations.
    // Inits the Annotation UI with a list of Tags.
    // TODO: Load Tags.
    init_img() {
          this.anno = new Annotorious({
            image: document.getElementById("annotation_image"),
            disableEditor: true
          });


          // Setting up the CUD Events
          this.anno.on('createAnnotation', this.craeteOrUpdateAnnotation);
          this.anno.on('updateAnnotation', this.craeteOrUpdateAnnotation);
          this.anno.on('deleteAnnotation', this.deleteAnnotation);
          this.anno.on('createSelection', this.saveAnnotation);

    },
    init(){

    this.anno= null         // Annotorious Object
    this.annotations= []    // Current Annotations
    this.init_img()
    
    },
  },
  
  data() {
      return {
        anno: null,         // Annotorious Object
        annotations: [],    // Current Annotations
      }
  },
  mounted() {
    this.init()          
  },
  computed: {
      img(){
          if(this.file)
            return URL.createObjectURL(this.file)
          else
            return ""
      }
  },
  watch: {
      file(){
          if(this.file){
              this.init()
          }
      }
  }

 }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.image-box {
  display: grid;
  height: 100%;
}
.image {
  margin: auto;
  max-width: 95%;
  max-height: 85vh;
  margin: auto;
}
.image img {
  border: solid grey 3px;
}
.alert{
  margin-top: 5px;
}
.badge {
    margin-right: 3px;
}
</style>
