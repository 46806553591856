<template>
<b-container fluid>
  <b-overlay :show="no_permission" rounded="lg">
        <b-skeleton-wrapper :loading="data_loading">

              <template #loading>
            <b-skeleton animation="wave" width="35%"></b-skeleton>
            <b-skeleton animation="wave" width="55%"></b-skeleton>
            <b-skeleton animation="wave" width="85%"></b-skeleton>
            <hr />
            <b-skeleton-img animation="wave" class="image" width="55%"></b-skeleton-img>
              </template>

      <b-row>
        <b-col>
                  <b-badge class="badge" v-if="location" pill><b-icon icon="globe" font-scale="1"></b-icon> {{ getTranslated(location.translations, 'name') }}</b-badge>
                  <b-badge class="badge" variant="light" v-for="activity in activities" v-bind:key="activity.id" pill><b-icon icon="tag" font-scale="1"></b-icon>{{ getTranslated(activity.translations, 'name') }}</b-badge>
        </b-col>
      </b-row>
      <hr />
      <b-row>

        <b-col class="image-box">
      
        <b-overlay class="image" :show="working" rounded="sm">
          <b-overlay class="image" :show="approved" rounded="sm">

              <div v-if="image.public">
              <img class="image" @load="init_img" :src="img" id="annotation_image" />
              </div>
              <div v-if="!image.public" class="image mx-auto">
              <b-icon icon="key-fill" font-scale="3" animation="throb"></b-icon>
                  <p>{{ $t('ImageAnnotation.error_not_published') }}</p>
              </div>

              <template #overlay>
              <div class="text-center">
                <b-icon icon="patch-check-fill" font-scale="3" animation="throb"></b-icon>
                <p>{{ $t('ImageAnnotation.already_approved') }}</p>
              </div>
          </template>

          </b-overlay>
    
        </b-overlay>



        </b-col>

        <b-col cols="2">
      <h1>{{ $t('ImageAnnotation.title') }}</h1>
      <p><small class="text-muted">ID: {{image.id}}</small></p>
      <p class="lead">{{ $t('ImageAnnotation.lead') }}</p>

      <b-button variant="secondary" @click="goto('metadata')" v-if="image.owner == user.url" block size="sm"><b-icon icon="pencil-square"></b-icon> {{ $t('ImageAnnotation.edit_image_metadata') }}</b-button>
      <b-button variant="secondary" @click="goto('upload')" block size="sm"><b-icon icon="upload"></b-icon> {{ $t('ImageAnnotation.upload_new_image') }}</b-button>
      <b-button variant="warning" @click="getAzureAnnotations" :disabled="azure_disabled" block size="sm"><b-icon icon="eyeglasses"></b-icon> {{ $t('ImageAnnotation.azure_object_detection') }}</b-button>
      <b-button variant="primary" @click="goto('next')" :disabled="last_image" block size="lg"><b-icon icon="bounding-box"></b-icon> {{ $t('ImageAnnotation.annotate_next_image') }}</b-button>

        <b-alert :show="last_image" class="alert" variant="warning">
          {{ $t('ImageAnnotation.last_image') }}
        </b-alert>
      <hr />
      <h3>{{ $t('ImageAnnotation.approved_header') }}</h3>
        <b-table :items="approved_items" :fields="approved_fields">
        </b-table>

      <b-button variant="success" v-if="!approved" @click="approve(true)" block size="lg"><b-icon icon="patch-check-fill"></b-icon> {{ $t('ImageAnnotation.approve_annotation') }}</b-button>
      <b-button variant="warning" v-if="approved" @click="approve(false)" block size="lg"><b-icon icon="patch-exclamation"></b-icon> {{ $t('ImageAnnotation.remove_approve_annotation') }}</b-button>

      </b-col>
      </b-row>

    </b-skeleton-wrapper>

       <template #overlay>
        <div class="text-center">
          <b-icon icon="key-fill" font-scale="3" animation="throb"></b-icon>
          <p>{{ $t('ImageMeta.no_permission') }} <b-link href="/dash">{{$t('dashboard')}}</b-link></p>
        </div>
     </template>

    </b-overlay>
    

</b-container>
</template>

<script>
import { apiService } from '../services'
import { translationService } from '../services'
import { Annotorious } from '@recogito/annotorious';
import ShapeLabelsFormatter from '@recogito/annotorious-shape-labels';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import '@recogito/annotorious/dist/annotorious.min.css';


export default {
  name: 'ImageAnnotation',
  components: {
  },
  props: [
    'user',
  ], 
  methods: {
  getTranslated: translationService.getTranslated,

  formatter(annotation) {
      console.log(annotation)
  const bodies = Array.isArray(annotation.body) ?
    annotation.body : [ annotation.body ];

  const firstTag = bodies.find(b => b.purpose == 'tagging');

  if (firstTag) {
    const foreignObject = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');

    // Overflow is set to visible, but the foreignObject needs >0 zero size,
    // otherwise FF doesn't render...
    foreignObject.setAttribute('width', '1px');
    foreignObject.setAttribute('height', '1px');

    foreignObject.innerHTML = `
      <div xmlns="http://www.w3.org/1999/xhtml" class="a9s-shape-label-wrapper">
        <div class="a9s-shape-label">
          ${firstTag.value}
        </div>
      </div>`;
    const r = {
      element: foreignObject,
      className: firstTag.value
    }
    console.log(r)
    return r;
  }
},
    getAzureAnnotations(){
      this.working = true
      apiService.get({url:`azure?id=${this.image.id}`})
        .then(response => {
          if(response.objects.length > 0){
            for(let i in response.objects){
          
            const id = uuidv4()
            const obj = response.objects[i]
            const annotation = {
                id: `#${id}`,
                body: [
                  {
                    type: "TextualBody",
                    value: obj.object,
                    purpose: "tagging",
                        creator: {
                        id: "https://azure.microsoft.com/de-de/services/cognitive-services/computer-vision/",
                        name: "Microsoft Azure"
                      }
                  }
                ],
                type: "Annotation",
                target: {
                  source: this.image.url,
                  selector: {
                    type: "FragmentSelector",
                    value: `xywh=pixel:${obj.rectangle.x},${obj.rectangle.y},${obj.rectangle.w},${obj.rectangle.h}`,
                    conformsTo: "http://www.w3.org/TR/media-frags/"
                  }
                },
              }
          this.azure_annotations.push(annotation)
          }
                
          }
          const annotation = this.azure_annotations.pop()

          if(annotation){
            this.anno.addAnnotation(annotation)
            this.anno.selectAnnotation(annotation.id)
          }
          this.working = false

        })
        .catch(error => {
          this.working = false
          this.$emit('toast', this.$t('ImageAnnotation.error_toast_azure.title'), this.$t('ImageAnnotation.error_toast_azure.message', {error}), 'danger')

        })
    },
    deleteAnnotation(annotation) {
      const id = annotation['id'].replace('#', '');
      apiService.get({url: `annotations/${id}/`, method: 'DELETE'})
              .then(() => {
                this.$emit('toast', this.$t('ImageAnnotation.success_toast.title'), this.$t('ImageAnnotation.success_toast.message'), 'success')
              })
              .catch(error => {
                this.$emit('toast', this.$t('ImageAnnotation.success_toast.title'), this.$t('ImageAnnotation.success_toast.message', {error}), 'danger')
              })
    },
    // Asynchronously saves or updated the annotation. 
    craeteOrUpdateAnnotation(annotation) {
          // Needs to be declared within method due to scope (this context not available within event listeners)
          const image_id = this.image_id;
          const image_url = `${process.env.VUE_APP_REST_URL}images/${image_id}/`;

            let tags = []
            
            for (let i in annotation['body']) {
              let t = annotation['body'][i]['value'];
              t = t.toLowerCase();
              tags.push(t);
              if(!this.vocabulary.includes(t)){
                this.vocabulary.push(t);
              }
            }

            // id needs to be a valid uuid, annotorious however prefixes them with #
            const id = annotation['id'].replace('#', '');

            const annotation_obj = {
              id: id,
              image: image_url,
              annotation: annotation,
              owner: this.user['url'], 
            }
            apiService.get({url: 'annotations/', method: 'POST', body: annotation_obj})
              .then(() => {
                this.$emit('toast', this.$t('ImageAnnotation.success_toast.title'), this.$t('ImageAnnotation.success_toast.message'), 'success')
              })
              .catch(error => {
                this.$emit('toast', this.$t('ImageAnnotation.success_toast.title'), this.$t('ImageAnnotation.success_toast.message', {error}), 'danger')
              })

            this.annotations.push(annotation);
    },

    // Triggered once the image loaded. Inits the Annotrious object, adds event listeners for Creating, Updating, Deleting Annotations.
    // Inits the Annotation UI with a list of Tags.
    // TODO: Load Tags.
    init_img() {
          this.anno = new Annotorious({
            image: document.getElementById("annotation_image"),
            widgets: [{ widget: 'TAG', vocabulary: this.vocabulary }],
            formatter: ShapeLabelsFormatter(),
            locale: this.$i18n.locale,
          });

          // Setting up the CUD Events
          this.anno.on('createAnnotation', this.craeteOrUpdateAnnotation);
          this.anno.on('updateAnnotation', this.craeteOrUpdateAnnotation);
          this.anno.on('deleteAnnotation', this.deleteAnnotation);

          // Annotations for Image
          apiService.get({url: `annotations/?image=${this.image_id}`})
                .then(response => {
                  if(response.count > 0){
                    for (let t in response.results){
                      this.annotations.push(response.results[t].annotation)
                    }

                    this.annotations.forEach(item => this.anno.addAnnotation(item));
                  } else {
                    this.getAzureAnnotations()
                  }

                })
              .catch(error => {
                this.$emit('toast', this.$t('ImageAnnotation.success_toast.title'), this.$t('ImageAnnotation.success_toast.message', {error}), 'danger')
              })
        },

    goto(target) {
      if(target === 'upload'){
        this.$router.push({name: 'ImageUpload'})
      }

      if(target === 'metadata'){
        this.$router.push({name: 'ImageMeta', params: {id:this.image.id}})
      }

      if(target === 'next'){
        if(!this.next_image)
          this.get_next_image()

        this.$router.push({name: 'ImageAnnotation', params: {id: this.next_image.id}})
      }
    },
    check_approved_annotations() {
      // Reset Vars
      this.annotation_approves = []
      this.approved = false
      this.approved_url = ''
      // Retrieve Approves for Annotatio
      apiService.get({url: `approves/?image=${this.image_id}`})
              .then(response => {
                this.annotation_approves = response.results
              })
              .then(() => {
                this.annotation_approves.forEach(element => {
                  if(element.user == this.user.url){
                    this.approved = true
                    this.approved_url = element.url
                  }
                })
              })
    },
    approve(val) {
      // Add Approval
      if(val){
        let approve_body = {
          user: this.user.url,
          image: this.image.url,
          approved: true
        }
          
        apiService.get({url: 'approves/', method: 'POST', body: approve_body})
              .then(() => {
                this.$emit('toast', this.$t('ImageAnnotation.success_toast.title'), this.$t('ImageAnnotation.success_toast.message'), 'success')
                this.check_approved_annotations()
              })
              .catch(error => {
                this.$emit('toast', this.$t('ImageAnnotation.success_toast.title'), this.$t('ImageAnnotation.success_toast.message', {error}), 'danger')
              })

      } else {
        // Revoke Approval
        apiService.get({url: `${this.approved_url}`, method: 'DELETE'})
              .then(() => {
                this.$emit('toast', this.$t('ImageAnnotation.success_toast.title'), this.$t('ImageAnnotation.success_toast.message'), 'success')
                this.check_approved_annotations()
              })
              .catch(error => {
                this.$emit('toast', this.$t('ImageAnnotation.success_toast.title'), this.$t('ImageAnnotation.success_toast.message', {error}), 'danger')
              })
      
      }
    },

    init(){
    moment.locale('de');
    this.vocabulary= []     // Vocab for Tags, initialized on load
    this.anno= null         // Annotorious Object
    this.annotations= []    // Current Annotations
    this.image_id= ""       // Current Image ID
    this.image= {}          // Image object
    this.data_loading= true
    this.next_image= ""
    this.last_image=false
    this.working = false
    this.azure_annotations = []

    // Image ID from Route
    this.image_id = this.$route.params.id;

    // Initializing variables.

    // Vocabulary for Annotation
    apiService.get({url: 'annotationtags/'})
          .then(response => {
            for (let t in response.results){
              const name = this.getTranslated(response.results[t].translations, 'name')
              this.vocabulary.push(name)
            }
          });

    // Retrieve checks for Annotation
    this.check_approved_annotations()
    
    // Meta-Data for Image
    apiService.get({url: `images/${this.image_id}/`})
      .then(response=>{
        if(response){
          this.image = response
          if(this.image.public){
            // Retrieve Location
            if(this.image.location){
              apiService.get({url: this.image.location})
                .then((response) => {
                  this.location = response
                })
            }

            // Retrieve Activities
            if(response.activities.length > 0){
              for(let a in response.activities){
                apiService.get({url: response.activities[a]})
                  .then(response => {
                    this.activities.push(response)
                  })
              }
            }
          }

        }
      })
        .then(() => {
          // After loading the image-data loading animation is turned off.
          this.data_loading = false
        })
      this.get_next_image()
    },
    get_next_image(){
          // Get Next Image in Line
      apiService.get({url: 'nextimage/', method: 'GET'})
            .then(response => {
              const options = response.results.filter(img => img.url != this.image.url)
              console.log(options)
              if(options.length > 0){
                this.next_image = options[0]
              } else {
                this.next_image = null
                this.last_image = true            
                }
            })
    }
  },
  
  data() {
      return {
        vocabulary: [],     // Vocab for Tags, initialized on load
        anno: null,         // Annotorious Object
        annotations: [],    // Current Annotations
        image_id: "",       // Current Image ID
        image: {},          // Image object
        data_loading: true,
        next_image: "",
        last_image: false,
        location: null,
        activities: [],
        working: false,
        azure_annotations: [],
        annotation_approves: [],
        approved_fields: ['Approved', 'User'],
        approved: false,
        approved_url: ''

      }
  },
  mounted() {
    this.init()          
  },
  computed: {
      approved_items() {
        let items = []
        let i = 1
        if(this.annotation_approves.length > 0){
          this.annotation_approves.forEach(element => {
            let item = {
              'User': `Admin ${i}`,
              'Approved': element.approved ? '✓' : '⚠',
              'Date':  moment(element.modified).format('ll LT')  
            }
            items.push(item)
            i++
          })
        }

        return items;
      },
      no_permission() {
        return !this.user.is_superuser;
      },
      img(){
        if(this.image.public){
          return `${process.env.VUE_APP_DJANGO_BASE_URL}image/${this.image_id}`
        } else {
          return ""
        }
      },
      azure_disabled(){
        return !(this.annotations.length == 0) || this.working || this.data_loading || !this.image.public
      }
  },
  watch: {
    '$route.params.id': function(){
      this.init()
    },
    next_image: function(){
      this.last_image = true

      if (this.next_image) {
        if(this.next_image.url != this.image.url){
        this.last_image = false
        }
      }

    },
    annotations: function(){
      if(this.azure_annotations.length > 0){
        const annotation = this.azure_annotations.pop()

        this.anno.addAnnotation(annotation)
        this.anno.selectAnnotation(annotation.id)

      }
    }
  },

 }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.image-box {
  display: grid;
  height: 100%;
}
.image {
  margin: auto;
  max-width: 95%;
  max-height: 85vh;
  margin: auto;
}
.image img {
  border: solid grey 3px;
}
.alert{
  margin-top: 5px;
}
.badge {
    margin-right: 3px;
}
.a9s-shape-label{
  font-size: x-large;
}
</style>
