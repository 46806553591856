<template>
    <div>
    <h1>{{ $t('Upload.ImageUploadForm.title') }}</h1>

     <p class="lead">
       {{ $t('Upload.ImageUploadForm.lead') }}
     </p>
     <p>{{ $t('Upload.ImageUploadForm.lead_extended') }}</p>
    <b-row>
      <b-col>
              <b-button v-b-toggle.help block variant="light"><b-icon icon="question-circle"></b-icon> {{$t('help')}}</b-button>
              <hr />
      </b-col>
    </b-row>
    <!-- Styled -->
    <b-row align-h="center" align-v="center" class="d-flex align-content-center flex-wrap">
      <b-col md="9" sm="12" class="upload-form">
        <b-form-file
          v-model="file"
          :state="Boolean(file)"
          :placeholder="$t('Upload.ImageUploadForm.placeholder')"
          drop-placeholder="$t('Upload.ImageUploadForm.drop_placeholder')"
          accept=".jpg, .jpeg, .png"
        ></b-form-file>
        </b-col>
      <b-col cols="3" class="d-none d-md-block">  
        <div>
        <h3>{{$t('Upload.ImageUploadForm.smartphone_upload')}}</h3>
        <QR redirect="image/upload" />

        <p class="small">{{ $t('Upload.ImageUploadForm.smartphone_upload_hint') }}</p>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import QR from '../QR.vue'

export default {
  name: 'ImageUploadForm',
  props: [
      'file'
  ],
  methods: {
  },
  data() {
      return {
      }
  },
  computed: {
  },
  watch: {
      file(){
          this.$emit('updateFile', this.file)
      }
  },
  components: {
    QR,
  }

 }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.button{
  margin-top: 5px;
}

.upload-form {
  padding-bottom: 25px;
}
</style>
