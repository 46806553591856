<template>
<div>
  <b-container>
      <img :src="image">
  </b-container>
</div>
</template>
<script>
import { apiService } from '../services'
import QRCode from 'qrcode'

export default {

  name: 'QR',
  components: {
  },
  data() {
      return {
          code: "",
          image: ""
      }
  },
  computed: {
  },
  methods: {
      
  },
  mounted() {
      console.log('Getting Code!')
      apiService.get({url: 'qr-auth'})
        .then((response) => {
            this.code = response.authcode;
        })
        .catch((error) => {
            console.log(error)
        })
  },
  watch: {
      code(){
          let redirect = ""
          if(this.redirect)
            redirect = `&redirect=${this.redirect}`
          
          const url = `${window.location.origin}/login?${this.code}${redirect}`

          console.log(url)
          QRCode.toDataURL(url)
            .then((url) => {
                this.image = url
            })
      }
  },
  props: {
      redirect: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
button {
    margin-right: 5px;
}
.card {
    margin-bottom: 15px;
}
</style>
